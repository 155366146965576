<template>
  <div class="app common-content">
    <div class="content_nav">
      <el-menu :default-active="activeIndex" mode="horizontal" :router="true">
        <el-menu-item
          class="content_nav_item"
          :route="{ name: 'Articles' }"
          index="0"
          ref="refNav"
          >全部文库</el-menu-item
        >
        <template v-for="(tag, index) in tags">
          <el-menu-item
            :index="index + 1 + ''"
            :route="{ name: 'Articles', query: { tag_id: tag.id, page: 1 } }"
            :key="index"
            >{{ tag.tag_name }}</el-menu-item
          >
        </template>
      </el-menu>
      <transition v-if="showSubTag">
        <div class="child_nav">
          <el-menu
            :default-active="activeSubIndex"
            mode="horizontal"
            :router="true"
          >
            <template v-for="(item, index) in childTag">
              <el-menu-item
                class="child_nav_item"
                :index="item.tag_parent + '-' + index"
                :route="{
                  name: 'Articles',
                  query: {
                    tag_id: item.tag_parent,
                    tag_sub_id: item.id,
                    page: 1
                  }
                }"
                :key="index"
                >{{ item.tag_name }}</el-menu-item
              >
            </template>
          </el-menu>
        </div>
      </transition>
    </div>
    <div class="content_main">
      <el-row :gutter="20" class="content_main_row">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
          class="content_main_col"
          v-for="(item, index) in news"
          :key="index"
        >
          <router-link
            active-class="news-item"
            :to="{ name: 'Librarydetail', params: { id: item.id } }"
            target="_blank"
          >
            <div class="content_list">
              <el-col :span="5">
                <img :src="item.cover || '../assets/images/news-img.jpg'" alt />
              </el-col>
              <el-col :span="14">
                <div class="content_list_con">
                  <h4>{{ item.title }}</h4>
                  <span>{{ item.digest }}</span>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="content_list_date">
                  <h1>{{ item.pub_date.slice(8) }}</h1>
                  <p>{{ item.pub_date.slice(0, 7) }}</p>
                </div>
              </el-col>
              <p class="news-free vip" v-html="item.pay_icon"></p>
            </div>
          </router-link>
        </el-col>
      </el-row>
    </div>
    <Page v-if="pageStatu" :total="total" @goTo="goTo"></Page>
  </div>
</template>
<script>
import qs from "qs";
import Page from "../components/Page.vue";
export default {
  components: {
    Page
  },
  data() {
    return {
      tagId: 0,
      tagSubId: 0,
      loading: true,
      activeIndex: "0",
      activeSubIndex: "0",
      tags: {},
      news: [],
      total: 0,
      pageStatu: false,
      baseUrl: "/api/web/articles",
      searchUrl: "",
      showSubTag: false,
      childTag: []
    };
  },
  methods: {
    goTo(page) {
      this.getArticles(page);
    },
    activeTag() {
      this.tags.map((item, key) => {
        if (this.tagId === item.id) {
          this.activeIndex = String(key + 1);
          this.childTag = item.children;
          this.childTag.map((subItem, subKey) => {
            if (subItem.id === this.tagSubId) {
              this.activeSubIndex = item.id + "-" + subKey;
            }
          });
          let clientWidth =
            document.documentElement.clientWidth || document.body.clientWidth;
          this.showSubTag = false;
          if (clientWidth >= 768) {
            this.showSubTag = true;
          }
        }
      });
    },
    // 获取文库列表
    getArticles(page = 1) {
      const params = {};
      if (this.tagId > 0) {
        params.tag_id = this.tagId;
        if (this.tagSubId > 0) {
          params.tag_children_id = this.tagSubId;
        }
      }
      params.page = page;
      let subData = qs.stringify(params);
      this.$store
        .dispatch("getArticles", subData)
        .then(res => {
          this.news = res.data;
          if (res.data.length > 0) {
            this.total = res.meta.total;
            this.pageStatu = true;
          } else {
            this.total = 0;
            this.pageStatu = false;
          }
          this.loading = false;
        })
        .then(() => {
          // 设置菜单
          if (this.tags.length > 0) {
            this.activeTag();
          }
        });
    },
    // 获取tag标签
    getProducts() {
      this.$store
        .dispatch("getProduct")
        .then(res => {
          this.tags = res;
        })
        .then(() => {
          this.activeTag();
        });
    }
  },
  mounted() {
    this.tagId = Number(this.$route.query.tag_id) || 0;
    this.tagSubId = Number(this.$route.query.tag_sub_id) || 0;
    this.getProducts();
    this.getArticles();
  },
  watch: {
    $route(to, from) {
      this.tagId = Number(this.$route.query.tag_id) || 0;
      this.tagSubId = Number(this.$route.query.tag_sub_id) || 0;
      this.showSubTag = false;
      this.activeSubIndex = "0";
      this.getArticles();
      // this.getProducts()
    }
  }
};
</script>
<style lang="less">
.app {
  .content_nav {
    margin: 2.5rem 0 0.5rem 0;
    /*position: relative;*/
    text-align: left;
    .child_nav {
      /*position: absolute;*/
      /*top: 4.25rem;*/
      /*left: 3.2rem;*/
      /*z-index: 5;*/
      margin-top: 0.5rem;
      padding-left: 3.2rem;
    }
    .el-menu--horizontal {
      .el-menu-item {
        line-height: 1rem;
        display: flex;
        align-items: center;
        white-space: normal;
        border: 1px solid @global-gray !important;
        padding: 0 0.3rem;
        &.content_nav_item {
          width: 3.2rem;
        }
        &.child_nav_item {
          min-width: 3.2rem;
          height: 2.5rem;
          margin-bottom: 0.5rem;
          font-size: 0.75rem;
        }
        & + .el-menu-item{
          border-left:0;
        }
      }
      .bordernone;
      .is-active {
        .bordernone;
        color: @global-white;
        background-color: @global-head-active-color;
      }
    }
  }
  .content_main {
    .content_main_row {
      margin: 0 0 !important;
      .content_main_col {
        margin: 1rem 0;
        .content_list {
          text-align: left;
          padding: 1.65rem 1.23rem;
          background-color: @global-personal-background;
          .flex;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .content_list_con {
            min-height: 7.8rem;
            h4 {
              color: @global-black;
              font-weight: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin: 0.6rem 0;
            }
            span {
              font-size: 0.88rem;
              color: @global-tab-color;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              overflow: hidden;
              -webkit-box-orient: vertical;
            }
          }
          h1,
          p {
            text-align: center;
          }
          .content_list_date {
            h1 {
              font-weight: normal;
              font-size: 1.9rem;
            }
            p {
              font-size: 0.88rem;
            }
          }
          .news-free {
            position: absolute;
            top: -0.92rem;
            left: -0.01rem;
            svg.icon {
              width: 4rem;
              height: 4rem;
            }
          }
        }
      }
    }
  }
}
.el-menu--horizontal {
  padding: 0 0.6rem;
  .el-menu--popup {
    min-width: 6.1rem;
    .el-menu-item {
      &.is-active {
        background-color: @global-head-active-color;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .app {
    .content_nav {
      margin: 0;
      .el-menu--horizontal {
        .flex;
        justify-content: space-between;
        .el-menu-item {
          width: 2.3rem !important;
          padding: 0 3px !important;
          height: 3rem;
          line-height: 1.25rem;
        }
      }
    }
    .content_main {
      .content_main_row {
        .content_main_col {
          padding: 0 !important;
          .content_list {
            padding: 0;
          }
        }
      }
    }
  }
  .el-menu--horizontal {
    padding: 0;
    .el-menu--popup {
      min-width: 5.25rem;
    }
  }
}
</style>
